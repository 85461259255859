<template>
	<NuxtLink
		class="blog-post-card"
		:to="{
			name: 'BlogPostPage',
			params: {
				blogPostSlug: blogPost.slug
			}
		}"
	>
		<div class="card-thumbnail">
			<NuxtImg
				:src="blogPost.mainImage || PLACEHOLDER_IMAGE_URL"
				alt="Imagem da postagem"
				width="500px"
				:placeholder="DEFAULT_NUXT_IMAGE_PLACEHOLDER"
				loading="lazy"
			/>
		</div>

		<div class="card-content">
			<span class="blog-post-title heading-font">
				{{ blogPost.title }}
			</span>

			<div class="blog-post-information">
				<span v-if="blogPost.author" class="blog-post-author-name default-font">
					{{ blogPost.author?.name }}
				</span>
				<span class="blog-post-date default-font">
					{{ formattedDate }}
				</span>
			</div>

			<span class="blog-post-description default-font">
				{{ blogPostDescription }}
			</span>
		</div>
	</NuxtLink>
</template>

<script setup lang="ts">
import type { BlogPost } from '@SHARED/core/entities/BlogPost';

import { DEFAULT_NUXT_IMAGE_PLACEHOLDER } from '@SHARED/utils/style';
import { removeMarkdownFormatting } from '@SHARED/utils/markdown';
import { PLACEHOLDER_IMAGE_URL } from '@SHARED/presenters/LabelPresenter';

defineOptions({ name: 'BlogPostCard' });

export type BlogPostCardProps = {
	blogPost: BlogPost;
};

const props = defineProps<BlogPostCardProps>();

const formattedDate = computed<string>(() => {
	const publishedAtDate = new Date(props.blogPost.publishedAt);

	return new Intl.DateTimeFormat('pt-BR').format(publishedAtDate);
});

const blogPostDescription = computed<string>(
	() =>
		props.blogPost.description ||
		removeMarkdownFormatting(props.blogPost.content).slice(0, 50) + '...'
);
</script>

<style lang="scss">
.blog-post-card {
	display: flex;
	flex-direction: column;
	overflow: hidden;
	cursor: pointer;
	transition: all 200ms ease-in-out;
	background: var(--white);
	height: auto !important;

	&:hover {
		box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
		.card-thumbnail {
			img {
				transform: scale(1.05);
			}
		}
	}
	.card-thumbnail {
		display: flex;
		flex-direction: column;
		transition: all 200ms ease-in-out;
		position: relative;
		z-index: 0;

		img {
			width: 100%;
			object-fit: cover;
			height: 16rem;
			transition: transform 0.3s ease-in-out;
		}
	}

	.card-content {
		background-color: var(--white);
		display: flex;
		flex-direction: column;
		padding: 1rem 1rem 1rem 1.5rem;
		gap: 0.75rem;
		z-index: 1;

		.blog-post-title {
			font-size: 1.5rem;
			color: var(--darkgray);
		}

		.blog-post-information {
			display: flex;

			.blog-post-author-name,
			.blog-post-date {
				font-size: 0.875rem;
				line-height: 100%;
				font-weight: 500;
				padding-right: 0.5rem;
				display: flex;
				align-items: center;

				&:not(:first-child) {
					padding-left: 0.5rem;
					border-style: solid;
					border-color: var(--black);
					border-width: 0;
					border-left-width: 1px;
				}
			}
		}

		.blog-post-description {
			font-weight: 300;
		}
	}
}
</style>
