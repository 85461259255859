// TODO: melhorar essa função usando menos regex (talvez usar apenas uma)
// TODO: adicionar testes
export function removeMarkdownFormatting(content: string): string {
	return content
		.replace(/^#+\s+.+$/m, '')
		.replace(/^#+\s+/gm, '')
		.replace(/^(\s*[-*+]\s+|(\d+\.))\s+/gm, '')
		.replace(/[*_]+/g, '')
		.replace(/\[([^\]]+)\]\([^)]+\)/g, '$1')
		.replace(/`+/g, '')
		.replace(/```[\s\S]+?```/g, '')
		.replace(/^[-*_]{3,}\s*$/gm, '')
		.replace(/!\[([^\]]+)\]\([^)]+\)/g, '')
		.trim();
}
